// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-day-js": () => import("./../src/templates/day.js" /* webpackChunkName: "component---src-templates-day-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-appendix-js": () => import("./../src/pages/appendix.js" /* webpackChunkName: "component---src-pages-appendix-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-week-1-mdx": () => import("./../src/pages/week1.mdx" /* webpackChunkName: "component---src-pages-week-1-mdx" */),
  "component---src-pages-week-2-mdx": () => import("./../src/pages/week2.mdx" /* webpackChunkName: "component---src-pages-week-2-mdx" */),
  "component---src-pages-week-3-mdx": () => import("./../src/pages/week3.mdx" /* webpackChunkName: "component---src-pages-week-3-mdx" */)
}

